@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    height: 100%;
}

body {
    min-height: 100%;
}

